/* Template Name: Upwind - Tailwind CSS Landing Page Template
   Author: Shreethemes
   Email: support@shreethemes.in
   Website: https://shreethemes.in
   Version: 1.8.0
   Created: March 2022
   File: Main Css File
*/

@tailwind base;
@tailwind components;
@tailwind utilities;

.nav-item {
   cursor: pointer;
}